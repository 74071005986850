import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, } from 'react-bootstrap';

import Commission from '../images/commission.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import axios from '../api/axios';

import { loadStripe } from '@stripe/stripe-js';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptDataString } from "../config/Configuration";
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

function Subscribe() {

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [lifeTimeData, setLifeTimeData] = useState([]);

  const [user_email, setUserLoggedin] = useState(null);
  const [users_name, setUserLoggedName] = useState(null);


  const [datasetId, setDatasetId] = useState(null);
  const [datasetToken, setDatasetToken] = useState(null);
  const [datasetPrice, setDatasetPrice] = useState(null);
  const [datasetUser, setDatasetUser] = useState(null);
  const [datasetName, setDatasetName] = useState(null);
  const [datasetInterval, setDatasetInterval] = useState(null);



  const [affilateModelStatus, setAffilateModelStatus] = useState(false);

  const [affilateText, setAffilateText] = useState(null);

  const [subscribeStatus, setSubscribeStatus] = useState(false);
  const [subscribeData, setSubscribeData] = useState([]);
  const [allowPromoCode, setAllowPromoCode] = useState(false);
  
  const token = JSON.parse(localStorage.getItem('token'));
  const user_id = localStorage.getItem('user_id');


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUserLoggedin(storedUser);
    }
  }, []);

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user_name'));
    if (storedUserName) {
      setUserLoggedName(storedUserName);
    }
  }, []);

  const handleBuynowButton = (token, planId, price, user, name, interval, status) => {
    setDatasetToken(token);
    setDatasetId(planId);
    setDatasetPrice(price);
    setDatasetUser(user);
    setDatasetName(name);
    setDatasetInterval(interval);
    if (status == 'create') {
      setAffilateModelStatus(true);

    }
    else {
      handleToken(token, planId, price, user, name, interval);
    }
  }
  const hideAffilateModel = () => {
    setAffilateModelStatus(false);
  };


  const handleAffilateLink = (event) => {
    setAffilateText(event.target.value);
  };

  const handleAffilateSkip = async () => {

    setAffilateModelStatus(false);
    handleToken(datasetToken, datasetId, datasetPrice, datasetUser, datasetName, datasetInterval);
  };

  const handleAffilateApply = async () => {
    dispatch(startLoading());
    const queryString = affilateText.split('?')[1];
    const queryParams = queryString ? queryString.split('&') : [];
    let idsArray = [];
    queryParams.forEach(param => {
      const [key, value] = param.split('=');
      if (key === 'ref') {
        idsArray = value.split(','); 
      }
    });
    try {

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      };

      const bodyParameters = {
        code: idsArray[0],
        id: decryptDataString(idsArray[0]),
        plan_id: datasetId
      };
      const response = await axios.post('/affiliate/save', bodyParameters, config);

      console.log('response', response);
      if (response.data.status) {
        if (response.data.status == true) {
          toast.success('Applied successfully');
          setAffilateModelStatus(false);
          handleToken(datasetToken, datasetId, datasetPrice, datasetUser, datasetName, datasetInterval);
        }
        else {
          toast.success(response.data.message);
          dispatch(stopLoading());

        }

      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      toast.error(e.message || "An error occurred. Please try again.");
    }

  };



  const navigate = useNavigate();
  const handleToken = async (token, planId, price, user, name, interval) => {

    dispatch(startLoading());
  
    const stripe = await loadStripe(process.env.REACT_APP_STRIP_PUBLISH_KEY);

   
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId: planId,
        email: user_email,
        name: users_name,
        interval: interval,
      }),
    });

    const session = await response.json();

    if(session.status==true)
    {
    if (session.type == 'created') {
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        console.error(result.error.message);
        dispatch(stopLoading());
      
        alert("Fail to purchase subscription");
      } else {
      
        alert('Payment successful!');
        navigate("/dashboard");
      }
    }
    else {
      toast.success('Plan updated');
      setTimeout(() => {
        window.location.reload();

      }, 2000);
    }
  }
  else{
    toast.error(session.message);
    dispatch(stopLoading());

  }



  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/pricing`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.status) {
          setData(responseData.data);
          setLifeTimeData(responseData.lifTimePlan);
        } else {
          throw new Error('Error fetching data');
        }
        dispatch(stopLoading());
      })
      .catch(error => {
        dispatch(stopLoading());
      });
  }, []);

  useEffect(() => {

    getSubscribeDetail();
  }, []);

  const getSubscribeDetail = async () => {
    dispatch(startLoading());
    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: user_id
    };
    await axios
   
      .post("/pricing/subscribeDetail", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data.total);

        setSubscribeStatus(res.data.subscribe_status);
        setAllowPromoCode(res.data.allowPromoCode)
        setSubscribeData(res.data.data);
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  const handleUnsubscribe = async (subscriptionId) => {
    if (!subscriptionId) {
      toast.error("Invalid subscription ID");
      return;
    }
  
  
    dispatch(startLoading());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.post(
        "/subscriptions/unsubscribe",
        { subscriptionId },
        config
      );
      if (response.data && response.status) {
        toast.success("Unsubscribed successfully!");
        getSubscribeDetail(); 
      } else {
        toast.error(response.data.message || "Failed to unsubscribe");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      dispatch(stopLoading());
    }
  };
  const handleConfirmation = (subscriptionId) => {
        
          const confirmationMessage = `Are you sure you want to unsubscribe this plan?`;
  
          confirmAlert({
              customUI: ({ onClose }) => (
                  <div className="custom-confirmation-box">
                      <h2 className="confirmation-title">Confirm Action</h2>
                      <p>{confirmationMessage}</p>
                      <div className="confirmation-buttons">
                          <button
                              style={{ backgroundColor: '#09D7A6', color: '#fff' }}
                              onClick={() => {
                                handleUnsubscribe(subscriptionId);
                                  onClose();
                              }}
                          >
                              Yes
                          </button>
                          <button
                              style={{
                                  backgroundColor: '#fff',
                                  color: '#09D7A6',
                                  border: '1px solid #09D7A6',
                              }}
                              onClick={onClose}
                          >
                              No
                          </button>
                      </div>
                  </div>
              ),
              overlayClassName: "custom-overlay", 
          });
      };

  return (
    <>
      <DashboarderHeader />
      <div className="MainContainer">

          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <main className="subscribePage content-wrapper">
              <section className='plan-divvv'>
                <div className='container'>
                  <Row className='justify-content-center mt-2'>
                    <Col md='8 justify-content-center'>
                      <div className='main-title mb-4'>Plan that Match your Needs</div>
                    </Col>
                  </Row>

                  {(() => {
                    if (lifeTimeData == null) {
                      return (
                        <div className='row'>
                          <div className='d-flex justify-content-center'>
                          <div className='col-md-8  col-sm-12'>
                            <div className='row'>
                              {
                                data &&
                                data.length > 0 &&
                                data.map(
                                  (item, i) => {
                                    return (<>

                                      <div className='col-md-6' key={item.id}>
                                        <div className={`match-div ${item.interval === 'year' ? 'active' : ''}`}>
                                          <span className='monthly-span mb-2'>{item.name}</span>
                                          <div className=' monthly-price flex-wrap'>
                                            {item.interval === 'year' ? (
                                              <>
                                                <span className='price-title '> €{item.price}</span> ${(item.price / 12).toFixed(2)}/Monthly
                                              </>
                                            ) : (
                                              <>€{item.price}</>
                                            )}
                                          </div>
                                           
                                          
                                          <hr />
                                          <div className='hhhhhhh' dangerouslySetInnerHTML={{ __html: item.desc }} />
                                          
                                          {(() => {

                                            if (subscribeStatus == false) {
                                              return (
                                                <div className='d-flex justify-content-between align-items-center mt-4'><a className='outline-greenColor-btn '
                                                  data-price={item.price}
                                                  data-user={user_email}
                                                  data-interval={item.interval}
                                                  data-name={item.name}
                                                  data-id={item.stripe_price_id}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleToken(
                                                      e.target.dataset.token,
                                                      e.target.dataset.id,
                                                      e.target.dataset.price,
                                                      e.target.dataset.user,
                                                      e.target.dataset.name,
                                                      e.target.dataset.interval,
                                                      'create'
                                                    );
                                                  }}
                                                  href="#"

                                                >Buy Now </a>
                                                {allowPromoCode==true && item.coupon_code_status==1 && item.coupon_code != null?<>
                                                  <p>Congrats, you will get <strong> {Math.trunc(item.affiliate_commission)} %  </strong>  off on purchase </p>
                                                
                                                </>:<></>}
                                                
                                                </div>


                                              )
                                            } else if (subscribeData && Object.keys(subscribeData).length > 0 && subscribeData.plan_id == item.id && subscribeData.plan.stripe_price_id == item.stripe_price_id) {
                                             
                                              
                                              if (subscribeData.active_plan_status === 1) {
                                                // Show Unsubscribe Button
                                                return (
                                                  <div className='d-flex justify-content-between align-items-center mt-4'><a  className='outline-greenColor-btn'
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleConfirmation(subscribeData.id);
                                                  }}
                                                  href="#"

                                                >Unsubscribe </a>
                                                <p className='mb-0'style={{color: "#09D7A6"}}>Active</p>
                                                </div>
                                                );
                                              } else if(subscribeData.active_plan_status === 2){
                                                return (
                                                  <div className='d-flex justify-content-between align-items-center mt-4'>
                                                    <p className='mb-0' style={{ color: "#09D7A6" }}>
                                                        {subscribeData.active_plan_status === 2 
                                                          ? `Cancel on ${subscribeData.unsubscribe_at}` 
                                                          : subscribeData.active_plan_status === 3 
                                                          ? "Expired" 
                                                          : ""}
                                                    </p>
                                                  </div>
                                                )
                                              }else {
                                                return (
                                                  <div className='d-flex justify-content-between align-items-center mt-4'><a className='outline-greenColor-btn '
                                                    data-price={item.price}
                                                    data-user={user_email}
                                                    data-interval={item.interval}
                                                    data-name={item.name}
                                                    data-id={item.stripe_price_id}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleToken(
                                                        e.target.dataset.token,
                                                        e.target.dataset.id,
                                                        e.target.dataset.price,
                                                        e.target.dataset.user,
                                                        e.target.dataset.name,
                                                        e.target.dataset.interval,
                                                        'create'
                                                      );
                                                    }}
                                                    href="#"
                                                  >Buy Now</a>
                                                  {allowPromoCode==true && item.coupon_code_status==1 && item.coupon_code != null?<>
                                                    <p>Congrats, you will get <strong> {Math.trunc(item.affiliate_commission)} %  </strong>  off on purchase </p>
                                                  
                                                  </>:<></>}
                                                  
                                                  </div>
                                                )
                                              }
                                             
                                            } 
                                            
                                            else if (subscribeData && Object.keys(subscribeData).length > 0 && subscribeData.active_plan_status === 3){
                                              return (
                                                <div className='d-flex justify-content-between align-items-center mt-4'><a className='outline-greenColor-btn '
                                                  data-price={item.price}
                                                  data-user={user_email}
                                                  data-interval={item.interval}
                                                  data-name={item.name}
                                                  data-id={item.stripe_price_id}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleToken(
                                                      e.target.dataset.token,
                                                      e.target.dataset.id,
                                                      e.target.dataset.price,
                                                      e.target.dataset.user,
                                                      e.target.dataset.name,
                                                      e.target.dataset.interval,
                                                      'create'
                                                    );
                                                  }}
                                                  href="#"
                                                >Buy Now</a>
                                                {allowPromoCode==true && item.coupon_code_status==1 && item.coupon_code != null?<>
                                                  <p>Congrats, you will get <strong> {Math.trunc(item.affiliate_commission)} %  </strong>  off on purchase </p>
                                                
                                                </>:<></>}
                                                
                                                </div>
                                              )
                                            }
                                          })()}



                                        </div>
                                      </div>
                                    </>)
                                  })

                              }

                            </div>
                          </div>
                        </div></div>
                      )
                    }
                  })()}
                </div>
              </section>
            </main>
          </div>



      <Modal show={affilateModelStatus} onHide={hideAffilateModel}>
        
        <Modal.Body>
          <div className='affilate-link'>Affiliate Link</div>
          <p>It is a long established fact that a reader will be distracted by the readable content.</p>
          <h3 className='refrral-title'>Share this referral link to your friends</h3>
          <div className='link'> <input value={affilateText} onChange={handleAffilateLink} className='custom-input affilate-link-input' id="affilate-link-input" type="text" placeholder='www.sample.info/?insect=fireman&porter=attraction#cave' />
          </div>
          <div className='ok-skip-div'>
            <button
              className='login-btn1' onClick={handleAffilateApply} href="#">Ok</button>
            <a className='login-btn1 skip-btn' onClick={handleAffilateSkip} href="#">Skip</a>
          </div>

        </Modal.Body>
       
      </Modal>

      <div className="modal custom-modal show" id="affilate" tabindex="-1" role="dialog" aria-labelledby="affilateTitle" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className='affilate-link'>Affiliate Link</div>
              <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              <h3 className='refrral-title'>Share this referral link to your friends</h3>
              <div className='link'>www.sample.info/?insect=fireman&porter=attraction#cave</div>
              <div className='ok-skip-div'>
                <a className='login-btn1' href="#">Ok</a>
                <a className='login-btn1 skip-btn' href="#">Skip</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Subscribe